.batch-metrics-toolbar {
  margin-left: 1px !important;
  margin-right: 1px !important;
  display: flex; }
  .batch-metrics-toolbar .metric-item {
    text-align: center;
    margin-bottom: 1rem;
    border: 0; }
    .batch-metrics-toolbar .metric-item.col-xl-2 {
      margin-bottom: 0; }
    .batch-metrics-toolbar .metric-item h4, .batch-metrics-toolbar .metric-item a {
      color: #3D5FC0;
      font-weight: 600; }
    .batch-metrics-toolbar .metric-item p {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .3rem;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: .5rem; }
      .batch-metrics-toolbar .metric-item p span {
        color: #3D5FC0; }
  @media screen and (max-width: 1470px) {
    .batch-metrics-toolbar .metric-item h4 {
      font-size: 1.1rem; }
    .batch-metrics-toolbar .metric-item p {
      font-size: .9rem; } }

.batch-metrics-toolbar .btn {
  margin-top: 0; }

.batch-metrics-footer .left {
  margin-bottom: 1rem; }

.batch-metrics-footer .right {
  display: flex;
  justify-content: center;
  align-items: center; }

.batch-metrics-footer .btn {
  margin: 0 0 0 1rem;
  padding-top: .3rem;
  padding-bottom: .3rem; }

.batch-metrics-footer .btn-link {
  padding-top: 0;
  padding-bottom: 0; }

.batch-metrics-footer .form-control {
  padding-top: .4rem;
  padding-bottom: .4rem; }

@media screen and (min-width: 1550px) {
  .batch-metrics-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .batch-metrics-footer .left {
      flex: 0 0 40%;
      margin-bottom: 0; }
    .batch-metrics-footer .right {
      margin-left: 1.5rem; } }

.metrics-facebook-share {
  position: absolute;
  top: .5rem;
  right: .5rem; }
  .metrics-facebook-share.disabled {
    cursor: not-allowed !important; }

.card .batch-metrics-toolbar {
  box-shadow: none; }

.batch-toolbox {
  margin-bottom: 2rem; }
  .batch-toolbox div[class^="col-"]:last-child {
    text-align: right; }
  .batch-toolbox .btn-group .dropdown .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px; }

.table .listing-table-cell {
  width: 20%;
  font-weight: 800;
  font-size: 12px;
  background-color: white;
  border: 1px solid #ccc; }

.table .listing-table-cell:last-child {
  border-right: 0; }

.listing-table-cell p {
  font-size: 12px; }

.listing-table-listing-actions {
  padding: 2px;
  background: transparent;
  color: #00943E; }

.search-result {
  background-color: white;
  padding: 20px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px; }

.search-result .search-result-badge {
  padding: 4px;
  font-size: 10px; }

.search-result .search-result-title {
  font-size: 14px; }

.amazon-btn {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 0px; }

.listing-table-listing-action-icon {
  font-size: 18px; }

.view .view-dashboard .sales-card .card + .card .card-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #eceff1; }
  .view .view-dashboard .sales-card .card + .card .card-block svg {
    transform: translate(0, -2px); }

.view .view-dashboard .sales-card .card + .card .card-block:last-child {
  border: 0; }

.site-search {
  position: relative;
  border: 1px solid #E7E7F0;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center; }
  .site-search input {
    font-size: 14px;
    border: none;
    padding-left: 3.8rem;
    transition: .2s ease-in;
    padding-top: .7rem;
    padding-bottom: .7rem; }
  .site-search > svg {
    position: absolute;
    top: .55rem;
    left: .7rem; }
  .site-search:before {
    content: "";
    position: absolute;
    left: 3rem;
    height: 100%;
    border-right: 1px solid #e1e6de; }

.Select, .react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    padding: 0.65rem 0.8rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #3c404c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #E7E7F0;
    border-radius: 0.15rem;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s; }

.react-datepicker-col > div {
  width: 100%; }

label {
  margin-bottom: .2rem !important; }

.popover {
  padding: 6px;
  min-width: 200px; }
  .popover .popover-header {
    background: #fff;
    padding: 0 0 10px 0;
    font-size: 16px;
    border-bottom: 1px solid #ebebeb; }
  .popover .row-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; }

.bs-popover-right {
  margin-left: 10px; }

.bs-popover-right::before {
  left: -11px;
  margin-top: -11px;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right::after {
  left: -10px;
  margin-top: -10px;
  border-right-color: #fff; }

.bs-popover-right::before,
.bs-popover-right::after {
  top: 50%;
  border-left-width: 0; }

.warehouse-badge {
  padding: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 5px;
  margin-top: 5px;
  border-radius: 2px;
  font-size: 11px;
  color: #fff;
  background-color: #000; }
  .warehouse-badge.BSM1 {
    background-color: #D69905; }
  .warehouse-badge.MOB1 {
    background-color: #D69905; }
  .warehouse-badge.PHX3 {
    background-color: #5BC805; }
  .warehouse-badge.PHX6 {
    background-color: #5BC805; }
  .warehouse-badge.PHX7 {
    background-color: #5BC805; }
  .warehouse-badge.TUS1 {
    background-color: #5BC805; }
  .warehouse-badge.PHX5 {
    background-color: #5BC805; }
  .warehouse-badge.PHX9 {
    background-color: #5BC805; }
  .warehouse-badge.TUS2 {
    background-color: #5BC805; }
  .warehouse-badge.AMZ2 {
    background-color: #05D627; }
  .warehouse-badge.OAK3 {
    background-color: #05D627; }
  .warehouse-badge.OAK4 {
    background-color: #05D627; }
  .warehouse-badge.SJC7 {
    background-color: #05D627; }
  .warehouse-badge.OAK6 {
    background-color: #05D627; }
  .warehouse-badge.OAK5 {
    background-color: #05D627; }
  .warehouse-badge.ONT2 {
    background-color: #05D627; }
  .warehouse-badge.ONT5 {
    background-color: #05D627; }
  .warehouse-badge.SNA7 {
    background-color: #05D627; }
  .warehouse-badge.SNA8 {
    background-color: #05D627; }
  .warehouse-badge.ONT6 {
    background-color: #05D627; }
  .warehouse-badge.ONT9 {
    background-color: #05D627; }
  .warehouse-badge.LGB4 {
    background-color: #05D627; }
  .warehouse-badge.SNA4 {
    background-color: #05D627; }
  .warehouse-badge.LGB6 {
    background-color: #05D627; }
  .warehouse-badge.SNA6 {
    background-color: #05D627; }
  .warehouse-badge.SNA9 {
    background-color: #05D627; }
  .warehouse-badge.LGB3 {
    background-color: #05D627; }
  .warehouse-badge.FAT1 {
    background-color: #05D627; }
  .warehouse-badge.SMF1 {
    background-color: #05D627; }
  .warehouse-badge.XUSD {
    background-color: #05D627; }
  .warehouse-badge.DEN2 {
    background-color: #05D68C; }
  .warehouse-badge.DEN3 {
    background-color: #05D68C; }
  .warehouse-badge.BDL1 {
    background-color: #05C5D6; }
  .warehouse-badge.PHL1 {
    background-color: #0598D6; }
  .warehouse-badge.PHL7 {
    background-color: #0598D6; }
  .warehouse-badge.MCO1 {
    background-color: #055ED6; }
  .warehouse-badge.MCO5 {
    background-color: #055ED6; }
  .warehouse-badge.MIA1 {
    background-color: #055ED6; }
  .warehouse-badge.MIA5 {
    background-color: #055ED6; }
  .warehouse-badge.TPA1 {
    background-color: #055ED6; }
  .warehouse-badge.TPA2 {
    background-color: #055ED6; }
  .warehouse-badge.JAX2 {
    background-color: #055ED6; }
  .warehouse-badge.JAX3 {
    background-color: #055ED6; }
  .warehouse-badge.JAX5 {
    background-color: #055ED6; }
  .warehouse-badge.MGE1 {
    background-color: #0A35B7; }
  .warehouse-badge.MGE3 {
    background-color: #0A35B7; }
  .warehouse-badge.ATL6 {
    background-color: #0A35B7; }
  .warehouse-badge.ATL8 {
    background-color: #0A35B7; }
  .warehouse-badge.ATL7 {
    background-color: #0A35B7; }
  .warehouse-badge.MDW2 {
    background-color: #614FCB; }
  .warehouse-badge.MDW4 {
    background-color: #614FCB; }
  .warehouse-badge.MDW5 {
    background-color: #614FCB; }
  .warehouse-badge.STL4 {
    background-color: #614FCB; }
  .warehouse-badge.STL6 {
    background-color: #614FCB; }
  .warehouse-badge.STL7 {
    background-color: #614FCB; }
  .warehouse-badge.MDW7 {
    background-color: #614FCB; }
  .warehouse-badge.IND1 {
    background-color: #D66205; }
  .warehouse-badge.XUSE {
    background-color: #D66205; }
  .warehouse-badge.IND2 {
    background-color: #D66205; }
  .warehouse-badge.IND3 {
    background-color: #D66205; }
  .warehouse-badge.IND5 {
    background-color: #D66205; }
  .warehouse-badge.IND4 {
    background-color: #D66205; }
  .warehouse-badge.SDF8 {
    background-color: #D66205; }
  .warehouse-badge.MKC6 {
    background-color: #D63705; }
  .warehouse-badge.MCI5 {
    background-color: #D63705; }
  .warehouse-badge.MKC4 {
    background-color: #D63705; }
  .warehouse-badge.SDF1 {
    background-color: #C70303; }
  .warehouse-badge.SDF2 {
    background-color: #C70303; }
  .warehouse-badge.SDF4 {
    background-color: #C70303; }
  .warehouse-badge.SDF6 {
    background-color: #C70303; }
  .warehouse-badge.SDF7 {
    background-color: #C70303; }
  .warehouse-badge.SDF9 {
    background-color: #C70303; }
  .warehouse-badge.CVG1 {
    background-color: #C70303; }
  .warehouse-badge.CVG2 {
    background-color: #C70303; }
  .warehouse-badge.CVG3 {
    background-color: #C70303; }
  .warehouse-badge.CVG5 {
    background-color: #C70303; }
  .warehouse-badge.CVG7 {
    background-color: #C70303; }
  .warehouse-badge.LEX1 {
    background-color: #C70303; }
  .warehouse-badge.LEX2 {
    background-color: #C70303; }
  .warehouse-badge.BWI2 {
    background-color: #278463; }
  .warehouse-badge.BWI5 {
    background-color: #278463; }
  .warehouse-badge.MDT2 {
    background-color: #278463; }
  .warehouse-badge.BOS7 {
    background-color: #648347; }
  .warehouse-badge.BOS5 {
    background-color: #648347; }
  .warehouse-badge.DET1 {
    background-color: #2D708D; }
  .warehouse-badge.DTW5 {
    background-color: #2D708D; }
  .warehouse-badge.MSP1 {
    background-color: #AC55D1; }
  .warehouse-badge.MSP5 {
    background-color: #AC55D1; }
  .warehouse-badge.LAS2 {
    background-color: #C01B7B; }
  .warehouse-badge.LAS6 {
    background-color: #C01B7B; }
  .warehouse-badge.RNO1 {
    background-color: #C01B7B; }
  .warehouse-badge.RNO4 {
    background-color: #C01B7B; }
  .warehouse-badge.BOS1 {
    background-color: #825C02; }
  .warehouse-badge.ABE8 {
    background-color: #387C03; }
  .warehouse-badge.ACY5 {
    background-color: #387C03; }
  .warehouse-badge.EWR4 {
    background-color: #387C03; }
  .warehouse-badge.EWR5 {
    background-color: #387C03; }
  .warehouse-badge.EWR6 {
    background-color: #387C03; }
  .warehouse-badge.EWR7 {
    background-color: #387C03; }
  .warehouse-badge.EWR9 {
    background-color: #387C03; }
  .warehouse-badge.LGA7 {
    background-color: #387C03; }
  .warehouse-badge.LGA8 {
    background-color: #387C03; }
  .warehouse-badge.EWR8 {
    background-color: #387C03; }
  .warehouse-badge.DEW2 {
    background-color: #387C03; }
  .warehouse-badge.DEW1 {
    background-color: #387C03; }
  .warehouse-badge.ACY1 {
    background-color: #387C03; }
  .warehouse-badge.TEB6 {
    background-color: #387C03; }
  .warehouse-badge.TEB3 {
    background-color: #387C03; }
  .warehouse-badge.BUF5 {
    background-color: #018D18; }
  .warehouse-badge.CLT2 {
    background-color: #016541; }
  .warehouse-badge.CLT5 {
    background-color: #016541; }
  .warehouse-badge.RDU5 {
    background-color: #016541; }
  .warehouse-badge.CMH1 {
    background-color: #04808B; }
  .warehouse-badge.CMH2 {
    background-color: #04808B; }
  .warehouse-badge.OKC1 {
    background-color: #28637C; }
  .warehouse-badge.PDX5 {
    background-color: #043E8C; }
  .warehouse-badge.PDX9 {
    background-color: #043E8C; }
  .warehouse-badge.ABE2 {
    background-color: #0C46F5; }
  .warehouse-badge.ABE3 {
    background-color: #0C46F5; }
  .warehouse-badge.ABE4 {
    background-color: #0C46F5; }
  .warehouse-badge.ABE5 {
    background-color: #0C46F5; }
  .warehouse-badge.AVP1 {
    background-color: #0C46F5; }
  .warehouse-badge.AVP2 {
    background-color: #0C46F5; }
  .warehouse-badge.AVP3 {
    background-color: #0C46F5; }
  .warehouse-badge.MDT1 {
    background-color: #0C46F5; }
  .warehouse-badge.PHL4 {
    background-color: #0C46F5; }
  .warehouse-badge.PHL6 {
    background-color: #0C46F5; }
  .warehouse-badge.PHL9 {
    background-color: #0C46F5; }
  .warehouse-badge.XUSC {
    background-color: #0C46F5; }
  .warehouse-badge.PHL5 {
    background-color: #0C46F5; }
  .warehouse-badge.PIT1 {
    background-color: #0C46F5; }
  .warehouse-badge.CAE1 {
    background-color: #392D7E; }
  .warehouse-badge.CAE3 {
    background-color: #392D7E; }
  .warehouse-badge.GSP1 {
    background-color: #392D7E; }
  .warehouse-badge.CHA1 {
    background-color: #A14C07; }
  .warehouse-badge.CHA2 {
    background-color: #A14C07; }
  .warehouse-badge.BNA1 {
    background-color: #A14C07; }
  .warehouse-badge.BNA2 {
    background-color: #A14C07; }
  .warehouse-badge.BNA3 {
    background-color: #A14C07; }
  .warehouse-badge.BNA5 {
    background-color: #A14C07; }
  .warehouse-badge.DFW6 {
    background-color: #842F14; }
  .warehouse-badge.FTW2 {
    background-color: #842F14; }
  .warehouse-badge.DFW7 {
    background-color: #842F14; }
  .warehouse-badge.HOU1 {
    background-color: #842F14; }
  .warehouse-badge.SAT1 {
    background-color: #842F14; }
  .warehouse-badge.SAT2 {
    background-color: #842F14; }
  .warehouse-badge.SLC1 {
    background-color: #FA2B2B; }
  .warehouse-badge.RIC1 {
    background-color: #13533D; }
  .warehouse-badge.RIC2 {
    background-color: #13533D; }
  .warehouse-badge.RIC3 {
    background-color: #13533D; }
  .warehouse-badge.BFI1 {
    background-color: #41582C; }
  .warehouse-badge.BFI7 {
    background-color: #41582C; }
  .warehouse-badge.BFI3 {
    background-color: #41582C; }
  .warehouse-badge.BFI4 {
    background-color: #41582C; }
  .warehouse-badge.BFI5 {
    background-color: #41582C; }
  .warehouse-badge.BFI6 {
    background-color: #41582C; }
  .warehouse-badge.SEA8 {
    background-color: #41582C; }
  .warehouse-badge.MKE1 {
    background-color: #4D96B6; }
  .warehouse-badge.MKE5 {
    background-color: #4D96B6; }
  .warehouse-badge.YYC1 {
    background-color: #773891; }
  .warehouse-badge.YVR2 {
    background-color: #871055; }
  .warehouse-badge.YVR3 {
    background-color: #871055; }
  .warehouse-badge.YYZ3 {
    background-color: #5E8C9F; }
  .warehouse-badge.YYZ4 {
    background-color: #5E8C9F; }
  .warehouse-badge.YYZ1 {
    background-color: #5E8C9F; }
  .warehouse-badge.YYZ2 {
    background-color: #5E8C9F; }
  .warehouse-badge.YOW1 {
    background-color: #5E8C9F; }
  .warehouse-badge.MEX1 {
    background-color: #806986; }
  .warehouse-badge.BHX1 {
    background-color: #EE6D6D; }
  .warehouse-badge.BHX2 {
    background-color: #EE6D6D; }
  .warehouse-badge.BHX3 {
    background-color: #EE6D6D; }
  .warehouse-badge.BHX4 {
    background-color: #EE6D6D; }
  .warehouse-badge.LBA1 {
    background-color: #EE6D6D; }
  .warehouse-badge.LBA2 {
    background-color: #EE6D6D; }
  .warehouse-badge.LTN4 {
    background-color: #EE6D6D; }
  .warehouse-badge.LTN2 {
    background-color: #EE6D6D; }
  .warehouse-badge.MAN1 {
    background-color: #EE6D6D; }
  .warehouse-badge.LTN1 {
    background-color: #EE6D6D; }
  .warehouse-badge.DPE1 {
    background-color: #EE6D6D; }
  .warehouse-badge.EUK5 {
    background-color: #EE6D6D; }
  .warehouse-badge.LCY2 {
    background-color: #EE6D6D; }
  .warehouse-badge.MAN2 {
    background-color: #EE6D6D; }
  .warehouse-badge.MAN3 {
    background-color: #EE6D6D; }
  .warehouse-badge.outline-badge {
    background-color: #fff;
    border: 1px solid #3A3F37; }

.outline-badge.BSM1 {
  color: #D69905;
  border-color: #D69905; }

.outline-badge.MOB1 {
  color: #D69905;
  border-color: #D69905; }

.outline-badge.PHX3 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.PHX6 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.PHX7 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.TUS1 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.PHX5 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.PHX9 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.TUS2 {
  color: #5BC805;
  border-color: #5BC805; }

.outline-badge.AMZ2 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.OAK3 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.OAK4 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SJC7 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.OAK6 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.OAK5 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.ONT2 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.ONT5 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SNA7 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SNA8 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.ONT6 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.ONT9 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.LGB4 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SNA4 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.LGB6 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SNA6 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SNA9 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.LGB3 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.FAT1 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.SMF1 {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.XUSD {
  color: #05D627;
  border-color: #05D627; }

.outline-badge.DEN2 {
  color: #05D68C;
  border-color: #05D68C; }

.outline-badge.DEN3 {
  color: #05D68C;
  border-color: #05D68C; }

.outline-badge.BDL1 {
  color: #05C5D6;
  border-color: #05C5D6; }

.outline-badge.PHL1 {
  color: #0598D6;
  border-color: #0598D6; }

.outline-badge.PHL7 {
  color: #0598D6;
  border-color: #0598D6; }

.outline-badge.MCO1 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.MCO5 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.MIA1 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.MIA5 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.TPA1 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.TPA2 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.JAX2 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.JAX3 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.JAX5 {
  color: #055ED6;
  border-color: #055ED6; }

.outline-badge.MGE1 {
  color: #0A35B7;
  border-color: #0A35B7; }

.outline-badge.MGE3 {
  color: #0A35B7;
  border-color: #0A35B7; }

.outline-badge.ATL6 {
  color: #0A35B7;
  border-color: #0A35B7; }

.outline-badge.ATL8 {
  color: #0A35B7;
  border-color: #0A35B7; }

.outline-badge.ATL7 {
  color: #0A35B7;
  border-color: #0A35B7; }

.outline-badge.MDW2 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.MDW4 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.MDW5 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.STL4 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.STL6 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.STL7 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.MDW7 {
  color: #614FCB;
  border-color: #614FCB; }

.outline-badge.IND1 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.XUSE {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.IND2 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.IND3 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.IND5 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.IND4 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.SDF8 {
  color: #D66205;
  border-color: #D66205; }

.outline-badge.MKC6 {
  color: #D63705;
  border-color: #D63705; }

.outline-badge.MCI5 {
  color: #D63705;
  border-color: #D63705; }

.outline-badge.MKC4 {
  color: #D63705;
  border-color: #D63705; }

.outline-badge.SDF1 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.SDF2 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.SDF4 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.SDF6 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.SDF7 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.SDF9 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.CVG1 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.CVG2 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.CVG3 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.CVG5 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.CVG7 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.LEX1 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.LEX2 {
  color: #C70303;
  border-color: #C70303; }

.outline-badge.BWI2 {
  color: #278463;
  border-color: #278463; }

.outline-badge.BWI5 {
  color: #278463;
  border-color: #278463; }

.outline-badge.MDT2 {
  color: #278463;
  border-color: #278463; }

.outline-badge.BOS7 {
  color: #648347;
  border-color: #648347; }

.outline-badge.BOS5 {
  color: #648347;
  border-color: #648347; }

.outline-badge.DET1 {
  color: #2D708D;
  border-color: #2D708D; }

.outline-badge.DTW5 {
  color: #2D708D;
  border-color: #2D708D; }

.outline-badge.MSP1 {
  color: #AC55D1;
  border-color: #AC55D1; }

.outline-badge.MSP5 {
  color: #AC55D1;
  border-color: #AC55D1; }

.outline-badge.LAS2 {
  color: #C01B7B;
  border-color: #C01B7B; }

.outline-badge.LAS6 {
  color: #C01B7B;
  border-color: #C01B7B; }

.outline-badge.RNO1 {
  color: #C01B7B;
  border-color: #C01B7B; }

.outline-badge.RNO4 {
  color: #C01B7B;
  border-color: #C01B7B; }

.outline-badge.BOS1 {
  color: #825C02;
  border-color: #825C02; }

.outline-badge.ABE8 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.ACY5 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR4 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR5 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR6 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR7 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR9 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.LGA7 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.LGA8 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.EWR8 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.DEW2 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.DEW1 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.ACY1 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.TEB6 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.TEB3 {
  color: #387C03;
  border-color: #387C03; }

.outline-badge.BUF5 {
  color: #018D18;
  border-color: #018D18; }

.outline-badge.CLT2 {
  color: #016541;
  border-color: #016541; }

.outline-badge.CLT5 {
  color: #016541;
  border-color: #016541; }

.outline-badge.RDU5 {
  color: #016541;
  border-color: #016541; }

.outline-badge.CMH1 {
  color: #04808B;
  border-color: #04808B; }

.outline-badge.CMH2 {
  color: #04808B;
  border-color: #04808B; }

.outline-badge.OKC1 {
  color: #28637C;
  border-color: #28637C; }

.outline-badge.PDX5 {
  color: #043E8C;
  border-color: #043E8C; }

.outline-badge.PDX9 {
  color: #043E8C;
  border-color: #043E8C; }

.outline-badge.ABE2 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.ABE3 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.ABE4 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.ABE5 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.AVP1 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.AVP2 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.AVP3 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.MDT1 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.PHL4 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.PHL6 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.PHL9 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.XUSC {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.PHL5 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.PIT1 {
  color: #0C46F5;
  border-color: #0C46F5; }

.outline-badge.CAE1 {
  color: #392D7E;
  border-color: #392D7E; }

.outline-badge.CAE3 {
  color: #392D7E;
  border-color: #392D7E; }

.outline-badge.GSP1 {
  color: #392D7E;
  border-color: #392D7E; }

.outline-badge.CHA1 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.CHA2 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.BNA1 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.BNA2 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.BNA3 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.BNA5 {
  color: #A14C07;
  border-color: #A14C07; }

.outline-badge.DFW6 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.FTW2 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.DFW7 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.HOU1 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.SAT1 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.SAT2 {
  color: #842F14;
  border-color: #842F14; }

.outline-badge.SLC1 {
  color: #FA2B2B;
  border-color: #FA2B2B; }

.outline-badge.RIC1 {
  color: #13533D;
  border-color: #13533D; }

.outline-badge.RIC2 {
  color: #13533D;
  border-color: #13533D; }

.outline-badge.RIC3 {
  color: #13533D;
  border-color: #13533D; }

.outline-badge.BFI1 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.BFI7 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.BFI3 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.BFI4 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.BFI5 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.BFI6 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.SEA8 {
  color: #41582C;
  border-color: #41582C; }

.outline-badge.MKE1 {
  color: #4D96B6;
  border-color: #4D96B6; }

.outline-badge.MKE5 {
  color: #4D96B6;
  border-color: #4D96B6; }

.outline-badge.YYC1 {
  color: #773891;
  border-color: #773891; }

.outline-badge.YVR2 {
  color: #871055;
  border-color: #871055; }

.outline-badge.YVR3 {
  color: #871055;
  border-color: #871055; }

.outline-badge.YYZ3 {
  color: #5E8C9F;
  border-color: #5E8C9F; }

.outline-badge.YYZ4 {
  color: #5E8C9F;
  border-color: #5E8C9F; }

.outline-badge.YYZ1 {
  color: #5E8C9F;
  border-color: #5E8C9F; }

.outline-badge.YYZ2 {
  color: #5E8C9F;
  border-color: #5E8C9F; }

.outline-badge.YOW1 {
  color: #5E8C9F;
  border-color: #5E8C9F; }

.outline-badge.MEX1 {
  color: #806986;
  border-color: #806986; }

.outline-badge.BHX1 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.BHX2 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.BHX3 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.BHX4 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LBA1 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LBA2 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LTN4 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LTN2 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.MAN1 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LTN1 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.DPE1 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.EUK5 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.LCY2 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.MAN2 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.outline-badge.MAN3 {
  color: #EE6D6D;
  border-color: #EE6D6D; }

.offers-panel {
  padding: 0px;
  padding-top: 10px; }

.offers-panel-list {
  height: 155px;
  overflow: scroll;
  width: 95%; }

.toolbox-btn {
  padding: 0.25rem 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  border-color: #ececec; }
  .toolbox-btn.btn {
    padding: 9px 15px; }
  .toolbox-btn.medium-right-margin {
    margin: 0; }

.toolbox-dropdownMenu {
  transform: translate3d(-125px, 45px, 0px) !important; }

.note-btn-popover {
  border-color: #ececec; }
  .note-btn-popover.btn {
    border-color: #eee;
    border-left-color: transparent; }
    .note-btn-popover.btn:hover, .note-btn-popover.btn:active, .note-btn-popover.btn.active {
      border-color: #3D5FC0; }

.search-results-visualizer {
  padding: 20px 15px;
  background-color: white; }
  .search-results-visualizer .header {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #e6e9ec; }
    .search-results-visualizer .header > .info {
      flex: 1 1 auto; }
    .search-results-visualizer .header:hover {
      background-color: #f9fafb;
      cursor: pointer; }
  .search-results-visualizer .media {
    margin-right: 15px;
    flex: 0 0 8%; }
  .search-results-visualizer .book-picture {
    max-width: 70px;
    max-height: 105px;
    margin: 0 auto; }
  .search-results-visualizer .book-title {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6; }
  .search-results-visualizer .meta-info p {
    margin-bottom: 5px; }

.profit-input {
  background-color: green;
  color: white;
  font-weight: 800; }

.view-dashboard-content {
  display: flex; }
  .view-dashboard-content .batch-core, .view-dashboard-content .batch-sidebar {
    transition: all .3s ease-in-out; }
  .view-dashboard-content .batch-core {
    flex: 0 0 auto;
    width: 100%; }
  .view-dashboard-content .batch-sidebar {
    transform: translateX(5rem); }
  .view-dashboard-content.show-sidebar .batch-core {
    flex: 0 0 auto;
    width: 75%; }
  .view-dashboard-content.show-sidebar .batch-sidebar {
    transform: translateX(0);
    flex: 0 0 auto;
    width: 25%;
    padding-left: 1.5rem; }
  .view-dashboard-content .card {
    margin-bottom: 30px; }

.batch-core > .row {
  margin-bottom: 30px; }

.batch-core .table {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09); }

.batch-core .Select-input {
  max-width: 15rem; }

.batch-header .batch-name {
  display: block;
  margin: 0 15px 0 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12rem;
  font-weight: 600;
  color: #3D5FC0; }

.batch-header .badge {
  position: relative;
  top: -2px; }

.batch-header .batch-type {
  text-transform: capitalize;
  color: #858C82; }

.batch-header .batch-metadata-badges {
  text-transform: capitalize; }
  .batch-header .batch-metadata-badges > img {
    margin-right: 5px; }
  .batch-header .batch-metadata-badges > span {
    line-height: 20px;
    vertical-align: middle; }

.batch-header .batch-metadata-group {
  text-align: right; }
  .batch-header .batch-metadata-group .batch-metadata-badges {
    margin-right: 15px; }
    .batch-header .batch-metadata-group .batch-metadata-badges:last-child {
      margin-right: 0; }

@media screen and (max-width: 1310px) {
  .batch-header .batch-metadata-badges span {
    display: none; } }

.pricing-panel {
  background-color: white;
  padding: 20px;
  margin-left: 1px !important;
  margin-right: 1px !important; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.date-picker-editeble-cell {
  display: inline !important;
  padding: 0.45rem 0.8rem !important; }

.holding-area-mgmt {
  margin-bottom: 30px; }
  .holding-area-mgmt h3 {
    margin-top: 10px; }
  .holding-area-mgmt .shipment-list {
    display: flex;
    background: #e9f0f3;
    border: 2px solid #b3b3b3;
    margin-top: 10px;
    padding: 5px;
    overflow-x: scroll; }
    .holding-area-mgmt .shipment-list .shipment {
      padding: 10px;
      text-align: center;
      min-width: 150px; }
      .holding-area-mgmt .shipment-list .shipment .stat {
        margin-top: 5px;
        max-width: 120px;
        margin: auto;
        text-align: left; }
        .holding-area-mgmt .shipment-list .shipment .stat span {
          font-size: 18px;
          font-weight: 600; }
        .holding-area-mgmt .shipment-list .shipment .stat svg {
          margin-right: 12px;
          color: grey; }
      .holding-area-mgmt .shipment-list .shipment .button {
        margin-top: 5px;
        padding: 8px 15px;
        color: white;
        border-radius: 5px;
        cursor: pointer; }
        .holding-area-mgmt .shipment-list .shipment .button.green {
          background-color: #17bb95; }
        .holding-area-mgmt .shipment-list .shipment .button.purple {
          background-color: #8055e8; }

.holding-common .code-container {
  max-width: 80px;
  margin: auto;
  margin-bottom: 12px;
  margin-top: 15px; }
  .holding-common .code-container .code .top {
    background: #e45a5a;
    color: white;
    font-size: 18px;
    height: 14px;
    display: block;
    border-radius: 0 5px 0 0;
    margin-bottom: 2px; }
    .holding-common .code-container .code .top:before {
      content: '';
      position: relative;
      width: 40px;
      height: 10px;
      background-color: #e45a5a;
      -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      display: block;
      top: -9px;
      left: 0px; }
  .holding-common .code-container .code .main {
    background: #e45a5a;
    width: 100%;
    display: inline-grid;
    border-radius: 0 0 5px 5px;
    padding: 10px;
    font-weight: 600;
    color: white; }

.shipment-list .scanned-product {
  border: 3px dashed grey;
  margin-bottom: 10px; }
  .shipment-list .scanned-product table {
    width: 100%; }
    .shipment-list .scanned-product table > tbody > tr td {
      padding: 5px; }
      .shipment-list .scanned-product table > tbody > tr td.count {
        width: 50px; }
        .shipment-list .scanned-product table > tbody > tr td.count span {
          background-color: #7bccb9;
          padding: 2px 25px;
          border-radius: 18px;
          border: 2px solid #17bb95;
          font-weight: 600; }
      .shipment-list .scanned-product table > tbody > tr td.checkbox-container {
        width: 25px;
        padding-top: 16px; }
        .shipment-list .scanned-product table > tbody > tr td.checkbox-container input[type="checkbox"] {
          height: 25px;
          width: 25px; }
      .shipment-list .scanned-product table > tbody > tr td.button-cell {
        width: 50px; }
      .shipment-list .scanned-product table > tbody > tr td.img-container {
        width: 70px; }
        .shipment-list .scanned-product table > tbody > tr td.img-container img {
          width: 50px; }
      .shipment-list .scanned-product table > tbody > tr td.title-container {
        width: 22.5%;
        text-align: center; }
      .shipment-list .scanned-product table > tbody > tr td.price {
        width: 100px;
        font-weight: 600;
        font-size: 24px; }
      .shipment-list .scanned-product table > tbody > tr td.attempts {
        text-align: right; }
        .shipment-list .scanned-product table > tbody > tr td.attempts .attempt-container {
          width: 100px;
          margin-left: auto;
          text-align: center; }
          .shipment-list .scanned-product table > tbody > tr td.attempts .attempt-container .attempt-count {
            background: #e45a5aa3;
            font-weight: 600;
            width: 32px;
            height: 32px;
            line-height: 30px;
            border-radius: 50%;
            margin: auto;
            border: 2px solid #f74d4d; }
      .shipment-list .scanned-product table > tbody > tr td.shipment-id {
        width: 100px; }
        .shipment-list .scanned-product table > tbody > tr td.shipment-id .code-container {
          max-width: 60px; }
          .shipment-list .scanned-product table > tbody > tr td.shipment-id .code-container .code .top {
            font-size: 18px;
            height: 7px; }
            .shipment-list .scanned-product table > tbody > tr td.shipment-id .code-container .code .top:before {
              width: 25px;
              height: 8px;
              top: -6px;
              left: 0px; }
          .shipment-list .scanned-product table > tbody > tr td.shipment-id .code-container .code .main {
            padding: 5px;
            text-align: center; }
      .shipment-list .scanned-product table > tbody > tr td .title {
        font-weight: 600;
        color: black;
        font-size: 16px; }

.loading-container {
  margin-bottom: 5rem; }

.search-result-item .meta-info {
  display: flex;
  justify-content: flex-start; }
  .search-result-item .meta-info > div {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid #d6dcd3; }
    .search-result-item .meta-info > div:last-child {
      border-right: 0; }

.card-batch-top {
  margin-bottom: 1rem !important; }
  .card-batch-top .separator-line {
    margin-top: 0;
    margin-bottom: .5rem; }
