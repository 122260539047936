.alert-pill {
  display: flex;
  align-items: center;
  color: #fff !important;
  border-radius: 5px !important;
  font-weight: 600;
  border-width: 2px !important;
  border-style: solid; }
  .alert-pill .icon {
    margin-right: 1rem;
    height: auto;
    background: transparent; }
    .alert-pill .icon svg {
      vertical-align: middle; }
  .alert-pill.alert-success {
    background-color: #1BC260;
    border-color: #16AE55; }
    .alert-pill.alert-success .icon svg, .alert-pill.alert-success .icon svg g, .alert-pill.alert-success .icon path {
      fill: #0E6532; }
  .alert-pill.alert-warning {
    background-color: #FCB036;
    border-color: #fca61d; }
    .alert-pill.alert-warning .icon svg, .alert-pill.alert-warning .icon svg g, .alert-pill.alert-warning .icon path {
      fill: #64400C; }
  .alert-pill.alert-danger {
    background-color: #FF7158;
    border-color: #ff5b3f; }
    .alert-pill.alert-danger .icon svg, .alert-pill.alert-danger .icon svg g, .alert-pill.alert-danger .icon path {
      fill: #691111; }
  .alert-pill.alert-dismissible .close {
    order: 1;
    top: auto;
    padding: 0.75rem 1rem; }
  .alert-pill.alert-outline {
    background-color: #fff;
    color: inherit !important; }
    .alert-pill.alert-outline.alert-warning .icon svg, .alert-pill.alert-outline.alert-warning .icon svg g, .alert-pill.alert-outline.alert-warning .icon path, .alert-pill.alert-outline.alert-danger .icon svg, .alert-pill.alert-outline.alert-danger .icon svg g, .alert-pill.alert-outline.alert-danger .icon path {
      fill: #FF7158; }
  .alert-pill.alert-sm {
    font-size: .9rem;
    padding: 0.2rem .7rem; }
