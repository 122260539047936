.table.batch-table {
  background: #FFFFFF;
  border: 1px solid #D9E0D5;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  position: relative; }
  .table.batch-table > thead > tr > th {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid #d6ded1;
    text-align: center; }
    .table.batch-table > thead > tr > th.title {
      width: 30%; }
  .table.batch-table tr.child td.child {
    background-color: #f5f6f8;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px; }
    .table.batch-table tr.child td.child .row {
      margin: 0 15px; }
    .table.batch-table tr.child td.child p {
      margin-bottom: .5rem; }
    .table.batch-table tr.child td.child .toggle-more {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 20px; }
    .table.batch-table tr.child td.child a {
      margin: 0;
      color: #545454; }
      .table.batch-table tr.child td.child a:hover, .table.batch-table tr.child td.child a:focus {
        color: #212121; }
  .table.batch-table td {
    vertical-align: middle;
    text-align: center;
    padding: 15px 10px; }
    .table.batch-table td a {
      color: #3c404c; }
    .table.batch-table td .warehouse-group {
      position: relative;
      width: 330%;
      margin-top: 1em;
      text-align: left; }
    .table.batch-table td .toggle-detail {
      margin: 0 10px 0 0;
      line-height: 1; }
    .table.batch-table td .media {
      flex: 1;
      margin-right: 15px; }
      .table.batch-table td .media img {
        max-width: 50px;
        max-height: 80px;
        margin: 0 auto; }
    .table.batch-table td .book-title {
      margin: 0;
      font-weight: normal;
      line-height: 1.3;
      text-align: left; }
    .table.batch-table td .action-controls {
      display: flex;
      gap: .7rem;
      align-items: center;
      justify-content: center; }
    .table.batch-table td .attribute-indicator > strong {
      display: inline-block; }
    .table.batch-table td .attribute-indicator-dot {
      font-size: 2rem;
      line-height: 0;
      vertical-align: middle;
      flex: 0 0 auto; }

.btn.btn-price-tracker {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background: #fff;
  text-align: center;
  padding: 5px;
  box-shadow: none;
  border: 1px solid #e0e3e5;
  border-radius: 3px; }
  .btn.btn-price-tracker.disabled_effect {
    filter: grayscale(90%); }

.toggle-more .caret {
  transition: all .3s ease-out;
  transform: rotate(-180deg); }

.toggle-more.collapsed .caret {
  transform: rotate(0); }

.title-link:hover, .title-link:focus {
  color: black; }

hr.separator-line {
  border-color: #D9E0D5; }

.shaking-button {
  animation: shake cubic-bezier(0.01, 0.07, 0.19, 0.97) both;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0); }

@keyframes shake {
  10%, 90% {
    transform: translate3d(0, 0, 0); }
  20%, 80% {
    transform: translate3d(0, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    transform: translate3d(2px, 0, 0); } }

.custom-research-button {
  display: inline-block;
  position: relative;
  margin-bottom: .8rem; }
  .custom-research-button .icon-delete {
    position: absolute;
    top: -3px;
    right: 1px;
    width: 8px;
    height: 8px;
    background-color: #FF7158; }

.sequence-reminder {
  min-width: 12rem; }
  .sequence-reminder .form-control {
    border-left: 0; }
  .sequence-reminder .input-group-prepend .input-group-text {
    background-color: #fff;
    border-right: 0; }
  .sequence-reminder input {
    cursor: pointer; }
