.loader-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader-container .loader {
    border: 7px solid white;
    border-top: 7px solid #00943E;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite; }

.StripeElement {
  box-shadow: none;
  margin: 0px;
  border: 1px solid #d6dbde;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  font-size: 18px !important;
  padding: 1rem; }

.StripeElement--focus {
  border: 1px solid #00943E; }

.register-plans {
  margin-bottom: 1.5rem; }
  .register-plans .pricing-selection {
    display: flex;
    justify-content: center; }
    .register-plans .pricing-selection .plan-box {
      position: relative;
      width: 18rem;
      margin: 0 1rem;
      padding: 1rem 1.1rem 3.3rem 1.1rem;
      border: 1px solid #ebeff1;
      border-radius: 5px;
      box-shadow: 0 4px 12px 0 rgba(21, 22, 32, 0.08);
      color: #383838; }
      .register-plans .pricing-selection .plan-box .price {
        position: absolute;
        width: 100%;
        bottom: .5rem;
        left: 0;
        font-size: 1.3rem;
        text-align: center;
        color: #00943E; }
        .register-plans .pricing-selection .plan-box .price small {
          color: #9b9b9b;
          font-weight: 600; }
      .register-plans .pricing-selection .plan-box small {
        font-size: 90%; }
      .register-plans .pricing-selection .plan-box:hover {
        cursor: pointer;
        box-shadow: none; }
      .register-plans .pricing-selection .plan-box.selected {
        background-color: #00943E;
        color: #FFF;
        border-color: #bdefd2;
        box-shadow: 0 0 10px rgba(21, 230, 107, 0.5);
        font-weight: 600; }
        .register-plans .pricing-selection .plan-box.selected .plan-box-features {
          list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAAAXNSR0IArs4c6QAAAKtJREFUKBWVjzsOwjAQRG06ShA1OQ+HgFtF4he4ERUcgZ6SAmTeAoNWxrKSlSb70b5ZJ4QBkVKKqEVn1PRGv2BHVlx6wWzbRQ+aQfuGKeZoXHJiPkIH5GNPEwOfBbqjE5p6A/oSuGMedfVKo/gZMKiDRrO0Qk/RZDOYoSPysaX5XMyelxvcPEVdBmXCQm4gvg46gyWE/4UN/f9TBeSZZTN4oPUgUEZAE9W1/AKu6j8jHf8WlgAAAABJRU5ErkJggg=="); }
        .register-plans .pricing-selection .plan-box.selected .price, .register-plans .pricing-selection .plan-box.selected .price small {
          color: #FFF; }
    .register-plans .pricing-selection .plan-box-features {
      padding-left: 1.5rem;
      margin-bottom: 0;
      list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAAAXNSR0IArs4c6QAAAQZJREFUKBVjZCAF/P/PyHAqZCoDA6M9AxuLNyPRev83MDGcvDKHgeF/IlgPI+M1JqI0o2sEafrPcACi+UyoHMPjUE6sBmHTyMg4h8F8dQ4Tw+kQT4a//24xPPt/lOFqqBCKAWCNl+fCnQqSBGk0W50GpP8zMfxlmAN0AjvD//+GDF/+74UbANfIkIAwkHE2TCPYHIaTIXFAxnygZogXGBkvMLAyujH8+tcFFE8AKYIAoEbz1ekgG+EiYAamAZ+AhvHBFAHpWQzmazKQNYLkEFF1IjSWgfH/ArgLEDqxakTVDOJhGoBTI8JsZNap4BiGEyF/gGExA+gKhMuQ1eBln08QwCsPlQQAdDJoBt7YsisAAAAASUVORK5CYII="); }

.register-page {
  display: flex;
  height: 100vh; }
  .register-page.view {
    padding: 0; }
  .register-page > .left {
    flex: 1 0 28rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 3rem;
    background-color: #fafafa;
    background-image: url("../../../assets/images/shape-dark.svg");
    min-height: 100vh;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff; }
  .register-page > .right {
    flex: 1 0 70%;
    padding: 1.5rem 4rem;
    overflow-y: auto; }
  .register-page .testimonial .icon-quote {
    display: block;
    margin-bottom: 1rem;
    color: #626f77; }
  .register-page .testimonial .text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6; }
  .register-page .testimonial .author {
    margin-top: 2rem; }
    .register-page .testimonial .author .name {
      margin-left: .8rem;
      font-weight: 500; }
  .register-page .bottom {
    text-align: center;
    font-size: 1.1rem; }
  .register-page .logos {
    display: grid;
    grid-template-columns: 50% 50%; }
    .register-page .logos img {
      margin-bottom: 1.5rem;
      justify-self: center; }
  .register-page form {
    max-width: 80rem;
    padding: 0 3rem;
    margin: 0 auto; }
    .register-page form .logo {
      margin-bottom: 2rem;
      text-align: center; }
    .register-page form .input-group svg {
      position: relative;
      top: auto;
      left: auto; }
    .register-page form .input-group .input-group-append {
      margin-left: -2px; }
    .register-page form .container-check .checkmark {
      top: 0; }
    .register-page form .form-text {
      font-size: .9rem; }
  .register-page .scroll {
    margin-top: 1rem;
    padding-right: 1rem;
    min-height: 10rem; }
    .register-page .scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #fafafa; }
    .register-page .scroll::-webkit-scrollbar {
      width: 12px;
      background-color: #ededed; }
    .register-page .scroll::-webkit-scrollbar-thumb {
      background-color: #ededed; }
  @media screen and (max-width: 1460px) {
    .register-page form {
      padding: 0 2rem; } }
  @media screen and (max-width: 1300px) {
    .register-page > .right {
      padding: 1.5rem .2rem; } }
  @media screen and (max-width: 1180px) {
    .register-page {
      flex-direction: column;
      height: auto; }
      .register-page > .left {
        order: 1;
        justify-content: flex-start;
        min-height: auto;
        padding-top: 2rem;
        padding-bottom: 2rem; }
      .register-page > .right {
        order: 0; }
      .register-page .testimonial {
        margin-bottom: 1rem; } }
  @media screen and (max-width: 800px) {
    .register-page form .check.ml-5 {
      margin-left: 1.5rem !important; } }
  @media screen and (max-width: 760px) {
    .register-page form .check,
    .register-page form .check.ml-5 {
      display: block !important;
      margin-left: 0 !important; } }
